import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { apiPaths, RESPONSE_STATUS } from "@src/utils/constants";
import { Consult } from "@src/views/account/mySessions/models/consult";
import { ConsultReview } from "@src/models/ConsultReview";
import { hours } from "./staletimes";

const identifier = `${apiPaths.getConsult}`;

export const useConsultsQuery = (
  includeNotes = false,
): ReturnType<typeof useQuery> => {
  const queryClient = useQueryClient();
  return useQuery([`${identifier}`], async (): Promise<Consult[]> => {
    const { data } = await axios.get(
      `/${identifier}?includeNotes=${includeNotes}`,
    );
    data.forEach((consult) =>
      queryClient.setQueryData(
        [consult.id, identifier, null, null, includeNotes],
        consult,
      ),
    );
    return data;
  });
};

export const useConsultQuery = (
  consultId,
  statusToFilterBy = null,
  statusToExclude = null,
  includeNotes = false,
) => {
  const queryParams: string[] = [];
  if (statusToFilterBy)
    queryParams.push(`statusToFilterBy=${statusToFilterBy}`);
  if (statusToExclude) queryParams.push(`statusToExclude=${statusToExclude}`);
  if (includeNotes) queryParams.push(`includeNotes=${includeNotes}`);
  const queryParamsString = Array.length ? `?${queryParams.join("&")}` : "";

  return useQuery(
    [consultId, identifier, statusToFilterBy, statusToExclude, includeNotes],
    async () => {
      try {
        const { data } = await axios.get(
          `/${identifier}/${consultId}${queryParamsString}`,
        );
        return data;
      } catch (error) {
        if (error.response.status === RESPONSE_STATUS.NOT_FOUND) {
          return null;
        }
      }
      return undefined;
    },
    {
      staleTime: hours(1),
    },
  );
};
export const useSubmitConsultReview = () =>
  useMutation(
    async ({
      consultId,
      review,
    }: {
      consultId: number;
      review: ConsultReview;
    }) => {
      await axios.post(`${identifier}/${consultId}/review`, review);
    },
  );

export const getConsultLiveStatus = async (consultId: number) => {
  try {
    const { data } = await axios.get(`/${identifier}/${consultId}/live-status`);
    return data;
  } catch (error) {
    if (error.response.status === RESPONSE_STATUS.NOT_FOUND) {
      return null;
    }
  }
  return undefined;
};
