import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { apiPaths } from "@src/utils/constants";
import { queryClient } from "@src/utils/queryClient";
import { EmployerSso, EmployerSsoRequest } from "@src/models/EmployerSso";
import {
  setAuthenticatedUserContext,
  clearAuthenticatedUserContext,
} from "@src/appInsights/AppInsights";
import { LOGOUT } from "../actions/typeActions";
import localStorageService from "./localStorage.service";

const getUserFromStorage = () =>
  JSON.parse(sessionStorage.getItem("user") as string);

const clearUserFromStorage = () => {
  sessionStorage.removeItem("user");
  clearAuthenticatedUserContext();
};

const saveUserToStorage = (user) => {
  if (user) {
    sessionStorage.setItem("user", JSON.stringify(user));
    setAuthenticatedUserContext(user.id);
  } else {
    clearUserFromStorage();
  }
};

const refreshAccessToken = async (email, token) => {
  await axios.post(`${apiPaths.refreshToken}?email=${email}`, {
    token,
  });
};

const registerBookingUser = async (
  firstName,
  lastName,
  email,
  yearOfBirth,
  employerId,
  phoneNumber,
  termsAndConditions,
  medicalDataProcessing,
  isPartner,
  token,
) => {
  const { data } = await axios.post(
    `${apiPaths.registerGuestUserAfterBooking}?token=${token}&email=${email}`,
    {
      firstName,
      lastName,
      email,
      yearOfBirth,
      employerId,
      phoneNumber,
      acceptedTermsAndConditions: termsAndConditions,
      acceptedMedicalDataProcessing: medicalDataProcessing,
      isPartner,
    },
  );

  return data;
};

const registerGuestUser = async (
  firstName,
  lastName,
  email,
  yearOfBirth,
  employerId,
  phoneNumber,
  termsAndConditions,
  medicalDataProcessing,
  isCheckIn,
  isPartner,
  isMedicalCheckin,
) => {
  const url = `${apiPaths.registerGuestUserAfterCheckIn}?isMedicalCheckIn=${isMedicalCheckin}`;

  const { data } = await axios.post(url, {
    firstName,
    lastName,
    email,
    yearOfBirth,
    employerId,
    phoneNumber,
    acceptedTermsAndConditions: termsAndConditions,
    acceptedMedicalDataProcessing: medicalDataProcessing,
    isPartner,
  });
  return data;
};

/**
 *
 * @param firstName
 * @param lastName
 * @param email
 * @param yearOfBirth
 * @param password
 * @param employerId
 * @param termsAndConditions
 * @param medicalDataProcessing
 * @param isPartner
 * @returns {Promise<boolean>} Boolean that indicates whether the user is logged in directly after registering.
 */
const registerUser = async (
  firstName,
  lastName,
  email,
  yearOfBirth,
  password,
  employerId,
  termsAndConditions,
  medicalDataProcessing,
  isPartner,
) => {
  const { data } = await axios.post(
    apiPaths.registerUser,
    Object.fromEntries(
      Object.entries({
        firstName,
        lastName,
        email,
        yearOfBirth,
        password,
        employerId,
        acceptedTermsAndConditions: termsAndConditions,
        acceptedMedicalDataProcessing: medicalDataProcessing,
        isPartner,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      }).filter(([_, v]) => v != null),
    ),
  );

  localStorageService.setUnconfirmedDataSignature(data.signature);
  return data;
};

const updateUser = async (
  firstName,
  lastName,
  email,
  yearOfBirth,
  signUpToNewsletter,
  employerId,
  termsAndConditions,
  medicalDataProcessing,
  isPartner,
) => {
  const url = apiPaths.updateUser;
  const { data } = await axios.put(
    url,
    Object.fromEntries(
      Object.entries({
        firstName,
        lastName,
        email,
        yearOfBirth,
        signUpToNewsletter,
        employerId,
        acceptedTermsAndConditions: termsAndConditions,
        acceptedMedicalDataProcessing: medicalDataProcessing,
        isPartner,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      }).filter(([_, v]) => v != null),
    ),
  );

  saveUserToStorage(data);
  return data;
};

export const getUserProfile = async (baseUrl: string | null = null) => {
  try {
    const { data } = await axios.get(apiPaths.profile, {
      baseURL: baseUrl || undefined,
    });

    saveUserToStorage(data);
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      return null;
    }
    throw new Error(error.response.data.message);
  }
};

// Verify User Email Api call
const verifyUserAccount = async (code, email) => {
  try {
    const response = await axios.put(
      `${apiPaths.confirmUser}?token=${code}&email=${email}`,
      {
        signature: JSON.parse(
          localStorageService.getUnconfirmedDataSignature() as string,
        )?.signature,
      },
    );
    if (response.status === 200) {
      return await getUserProfile();
    }
  } catch (error) {
    return Promise.reject(error);
  }
  return undefined;
};

// Resend Email Activation Link Api call
const resendVerificationEmail = async (email) => {
  const { data } = await axios.post(
    `${apiPaths.sendVerificationEmail}?email=${email}`,
  );
  return data;
};

// Send forgot password Access Link Api call
const sendForgotPasswordAccessMail = async (email) => {
  const { data } = await axios.post(
    `${apiPaths.sendPasswordResetEmail}?email=${email}`,
  );
  return data;
};

// Update User Password Api Call
const updateUserPassword = async (code, userId, password) => {
  await axios.put(apiPaths.resetPassword, {
    code,
    userId,
    password,
  });
  try {
    const response = await getUserProfile();
    return response;
  } catch (error) {
    return new Error(error);
  }
};

const getExternalUser = async (
  idToken,
  provider,
  baseUrl: string | null = null,
) => {
  const { data } = await axios.post(
    apiPaths.externalLogin,
    {
      idToken,
      provider,
    },
    {
      baseURL: baseUrl || undefined,
    },
  );
  if (data?.twoFactorVerification === "pending") {
    return data;
  }
  return getUserProfile();
};

const getExternalUserCodeFlow = async (
  code,
  provider,
  baseUrl: string | null = null,
) => {
  let domain = window.location.hostname;
  if (window.location.port && window.location.port !== "443") {
    domain = `${domain}:${window.location.port}`;
  }
  const { data } = await axios.post(
    apiPaths.loginExternalCodeFlow,
    {
      code,
      provider,
      domain,
    },
    {
      baseURL: baseUrl || undefined,
    },
  );
  if (data?.twoFactorVerification === "pending") {
    return data;
  }
  return getUserProfile();
};

const signUpExternalUser = async (idToken, provider) => {
  const { data } = await axios.post(apiPaths.externalSignUp, {
    idToken,
    provider,
  });
  if (data?.twoFactorVerification === "pending") {
    return data;
  }
  return getUserProfile();
};

const signUpExternalUserCodeFlow = async (
  code,
  provider,
  baseUrl: string | null = null,
) => {
  try {
    let domain = window.location.hostname;
    if (window.location.port && window.location.port !== "443") {
      domain = `${domain}:${window.location.port}`;
    }
    const { data } = await axios.post(
      apiPaths.externalSignUpCodeFlow,
      {
        code,
        provider,
        domain,
      },
      {
        baseURL: baseUrl || undefined,
      },
    );
    if (data?.twoFactorVerification === "pending") {
      return data;
    }
    return await getUserProfile();
  } catch (error) {
    return Promise.reject(error);
  }
};

const getGuestUser = async (token, email) => {
  const { data } = await axios.get(
    `${apiPaths.guestLogin}?token=${token}&email=${email}`,
  );
  if (data?.twoFactorVerification === "pending") {
    return data;
  }
  return getUserProfile();
};

const getEmployerSso = async (employerSsoRequest: EmployerSsoRequest) => {
  const { data } = await axios.post<EmployerSso>(
    apiPaths.companySsoSupport,
    employerSsoRequest,
  );
  return data;
};

// Verify user credentials
const login = async (userName, password, baseUrl: string | null = null) => {
  saveUserToStorage({ email: userName });
  const { data } = await axios.post(
    apiPaths.login,
    {
      userName,
      password,
    },
    {
      baseURL: baseUrl || undefined,
    },
  );

  if (data?.twoFactorVerification === "pending") {
    return data;
  }
  return getUserProfile();
};

// Verify Email Id Api call
const isEmailRegistered = async (email) => {
  const { data } = await axios.get(`${apiPaths.emailRegistered}/${email}`);
  return data;
};

// Log out & Remove Local storage
const logout = async () => {
  await axios.post(apiPaths.logout, {});
  sessionStorage.removeItem("user");
  queryClient.clear();
};

export const useAuthentication = () => {
  const isLoggedIn = useSelector((state) => !!state.authReducers?.isLoggedIn);
  const isLoggedInOrTwoFactorPending = useSelector(
    (state) =>
      !!state.authReducers?.isLoggedIn ||
      !!(
        state.authReducers?.user?.twoFactorVerification &&
        state.authReducers?.user?.twoFactorVerification === "pending"
      ),
  );
  const user = useSelector((state) => state.authReducers?.user);
  const dispatch = useDispatch();

  const newLogout = () => {
    dispatch({ type: LOGOUT });
  };

  return {
    isLoggedIn,
    isLoggedInOrTwoFactorPending,
    logout: newLogout,
    user,
  };
};

const service = {
  registerUser,
  updateUser,
  registerGuestUser,
  registerBookingUser,
  getGuestUser,
  resendVerificationEmail,
  login,
  logout,
  verifyUserAccount,
  sendForgotPasswordAccessMail,
  updateUserPassword,
  getExternalUser,
  getExternalUserCodeFlow,
  isEmailRegistered,
  getUserFromStorage,
  saveUserToStorage,
  clearUserFromStorage,
  refreshAccessToken,
  signUpExternalUser,
  signUpExternalUserCodeFlow,
  getEmployerSso,
};

export default service;
